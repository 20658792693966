<!--
 * @Author: kevinzgai zhanggai_ok@126.com
 * @Date: 2023-11-27 14:37:26
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2023-12-05 10:28:10
 * @FilePath: \zyt-zlb-mobile-develop\src\views\cinemaAssistant\competitorCinema\benefitDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div>
      <van-cell-group>
        <van-field
          label="当前影院: "
          :custom-style="{ color: 'red' }"
          style="color: aliceblue"
          :value="ourCinema.cinemaName"
          readonly
        />
        <!-- 选中竞对影院 -->
        <van-field
          label="竞对影院: "
          placeholder="请选择竞对影院"
          custom-style="color: aliceblue;"
          clickable
          readonly
          right-icon="arrow-down"
          :value="selectedCompetitorCinema.cinemaName"
          @click-input="handleCompetitorCinemaClick"
        >
        </van-field>
      </van-cell-group>
    </div>
    <van-tabs color="#1989fa" :active="activeTab" @change="tabChange">
      <van-tab title="昨天" name="yesterday"> </van-tab>
      <van-tab title="近7天" name="before7days"> </van-tab>
      <van-tab title="近30天" name="before30days"> </van-tab>
    </van-tabs>
    <div>
      <ve-table
        :scroll-width="0"
        :table-data="filmData"
        :max-height="maxHeight"
        :fixed-header="true"
        :columns="columns"
        :border-y="true"
      ></ve-table>
    </div>
    <van-popup
      v-model="showCompetitorCinema"
      round
      position="center"
      :style="{ width: '90%', height: '50%' }"
      @click-overlay="onClose"
    >
      <van-picker
        show-toolbar
        title="请选择竞对影院"
        value-key="cinemaName"
        :columns="competitorCinemaList"
        @cancel="onClose"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
export default {
  data() {
    return {
      activeTab: '昨天',
      cinemaName: '',
      // 表格最大高度
      maxHeight: 900,
      filmData: [],
      // 我方影院
      ourCinema: {},
      // 竞对方影院
      competitorCinema: {},
      columns: [
        {
          title: '序号',
          width: 60,
          field: 'index',
          key: 'index',
          fixed: 'left',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <div>
                  <span>{rowIndex + 1}</span>
                </div>
              </div>
            )
          },
        },
        {
          title: '影片名称',
          fixed: 'left',
          field: 'filmName',
          key: 'filmName',
          width: 120,
        },
        {
          title: '累计票房',
          field: 'totalBoxOffice',
          children: [
            {
              key: 'totalBoxOffice-our',
              field: 'totalBoxOffice',
              title: '当前影院',
              width: 90,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.totalBoxOffice.our.toFixed(2)}元</span>
                    </div>
                  </div>
                )
              },
            },
            {
              key: 'totalBoxOffice-competitor',
              field: 'totalBoxOffice',
              title: '竞对影院',
              width: 90,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.totalBoxOffice.competitor.toFixed(2)}元</span>
                    </div>
                  </div>
                )
              },
            },
          ],
        },
        {
          title: '平均单座收益',
          field: 'averagePrice',
          children: [
            {
              key: 'averagePrice-our',
              field: 'averagePrice',
              title: '当前影院',
              width: 90,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.averagePrice.our.toFixed(2)}元</span>
                    </div>
                  </div>
                )
              },
            },
            {
              key: 'averagePrice-competitor',
              field: 'averagePrice',
              title: '竞对影院',
              width: 90,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.averagePrice.competitor.toFixed(2)}元</span>
                    </div>
                  </div>
                )
              },
            },
          ],
        },
      ],
      // 竞对影院列表
      competitorCinemaList: [],
      // 选中的竞对影院
      selectedCompetitorCinema: {},
      // 显示竞对影院列表
      showCompetitorCinema: false,
    }
  },
  mounted() {
    // 动态计算表格最大高度
    this.maxHeight = window.innerHeight - 100
    // 获取当前影院名称
    this.ourCinema = JSON.parse(this.$route.query.ourCinema)
    this.getCompetitorCinema().then((res) => {
      console.log({ res })
      this.selectedCompetitorCinema = res[0]
      this.getAnalysisBenefitInfo()
    })
  },
  methods: {
    /**
     * @description: 获取竞对影院
     * @return {*}
     */
    getCompetitorCinema() {
      console.log(this.$route.query)
      const query = {
        UserCode: this.$store.state.userInfo.mobile,
        ourCinemaId: this.$route.query.id,
      }
      console.log('获取竞对影院')
      return new Promise((resolve, reject) => {
        zjApi
          .getCompetitorList(query)
          .then((res) => {
            console.log({ res })
            this.competitorCinemaList = res
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * @description 获取影院和竞对影院排⽚效益详情
     */
    getAnalysisBenefitInfo() {
      const query = {
        UserCode: this.$store.state.userInfo.mobile,
        ourCinemaId: this.$route.query.id,
        competitorCinemaId: this.selectedCompetitorCinema.id,
        dateType: this.activeTab,
      }
      console.log('获取影院和竞对影院排⽚效益详情', query)
      return new Promise((resolve, reject) => {
        zjApi
          .getAnalysisBenefitInfo(query)
          .then((res) => {
            console.log({ res })
            this.filmData = res
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * @description: 选中竞对影院
     * @return {*}
     */
    handleCompetitorCinemaClick() {
      console.log('选中竞对影院')
      this.showCompetitorCinema = true
      console.log(
        { showCompetitorCinema: this.showCompetitorCinema },
        '执行竞对查询',
      )
    },
    // 关闭竞对影院列表
    onClose() {
      this.showCompetitorCinema = false
    },

    /**
     * @description: 确认选中竞对影院
     * @param {*} value 选中的竞对影院
     * @param {*} index 选中的竞对影院索引
     * @return {*}
     */
    onConfirm(value, index) {
      console.log({ value, index })
      this.selectedCompetitorCinema = value
      this.showCompetitorCinema = false
      this.getAnalysisBenefitInfo()
    },
    /**
     * @description: 切换tab
     * @param {*} tab
     * @return {*}
     */
    tabChange(tab) {
      console.log(tab)
      this.activeTab = tab
      this.getAnalysisBenefitInfo()
    },
  },
}
</script>
<style scoped lang="less">
.my-cell {
  color: red;
}
</style>
